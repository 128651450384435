.container-planning{
  display: flex;
}

#display-date{
    margin: 0 0 0 140px;
    display:'inline-block'
}

.container-users {
  box-shadow: 3px 0 5px -5px #121316;
}

.container-days {
  display:flex;
  flex-direction: row;
  align-items: center;
  /*flex-wrap: wrap;*/
  overflow-x: auto;
  scroll-behavior: smooth;

  &.projetDays {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
  }

  .load{
      text-align: center;
      min-width:30px;
      height:30px;
      line-height: 30px;
      border-radius: 20px;
      margin:10px;
      background:$tonic-blue;
      color:$white;
      font-size: 18px;
  }
  .days {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    p {
      width: 130px;
      margin: 0px;
    }

    .day-content{
        min-width: 32px;
        height: 24px;
        text-align: center;
        margin-right: 5px;
        display: flex;
        flex-direction: column;
    }

    .day {
      border-radius: 8px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      color:#121316;
      --red: 0;
      --green: 0;
      --blue: 0;
      --luminosite: calc( ( var(--red)*299 + var(--green)*587 + var(--blue)*114 ) / 1000 );
      --couleur: calc( (var(--luminosite) - 128) * -255000);
      color: rgb(var(--couleur), var(--couleur), var(--couleur));
    }

    .day.active {
      background-color: #121316;
      box-shadow: $box-shadow-small;
      border:solid 1px #121316;
    }

    .day.select {
      box-shadow: $box-shadow-small;
      background-color: #fa5c4f !important;
      color:#fff;
      border:solid 1px #fa5c4f !important;
    }

    .day.empty {
      background-color: $dark-grey;
      box-shadow:none;
      border:dashed 1px #fa5c4f;
    }

    .day.publicHoliday {
      color: #707070;
      box-shadow:none;
      background-color: $dark-grey;
      border:solid 1px $dark-grey;
      cursor: not-allowed;
    }

    .day.busy {
      color: #707070;
      background-color: $dark-grey;
      border:solid 1px $dark-grey;
    }

    .day.weekend {
      background-color: $dark-grey;
      border:solid 1px #707070;
      color:#707070;
      cursor: not-allowed;
    }

    .day.half {
        height: 10px;
      line-height: 10px;
      font-size: 10px;
    }
    .day.half:first-child {
        margin-bottom:2px
    }

    .day.open {
      background-color: #e0c7c5 !important;
      width:120px;
      height: 24px;
      line-height: 24px;
      a {
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
          text-decoration: underline;
      }
      a.active{
          background-color: #fa5c4f;
          color:$white;
          border-radius: 8px;
      }
    }

  }

}
