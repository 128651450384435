// Google Fonts
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,300,700,400italic);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,300,700,400italic);

$font-stack: 'Open Sans', sans-serif;
$main-background: #F4F5F7;
$dark-background: #222429;
$disabled: #868DB1;
$dark-blue: #b1473f;
$tonic-blue: #fa5c4f;
$white: #ffffff;
$dark-grey: #1c1e22;
$hover-text: #555;
$error-color: #c92b2b;
$warn-color: #FF8F4C;
$confirm-color: #80c92b;

$box-shadow: 5px 5px 10px #1c1c1c, -5px -5px 10px #202020;
$box-shadow-small: 2px 2px 4px #1c1c1c, -2px -2px 4px #202020;
/*$box-shadow: 5px 5px 10px #cfd0d2, -5px -5px 10px #ffffff;*/

@import "partials/modal";
@import "partials/sidebar";
@import "partials/col";
@import "partials/tabs";
@import "partials/login";
@import "partials/forms";
@import "partials/line";
@import "partials/tile";
@import "partials/languages";
@import "partials/document";
@import "partials/holiday";
@import "partials/day";
@import "partials/responsive";

/*
GENERAL
*/

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  background-color: $dark-background;
}

html {
  scrollbar-face-color: #646464;
  scrollbar-base-color: #646464;
  scrollbar-3dlight-color: #646464;
  scrollbar-highlight-color: #646464;
  scrollbar-track-color: $dark-grey;
  scrollbar-arrow-color: $dark-grey;
  scrollbar-shadow-color: #646464;
  scrollbar-dark-shadow-color: #646464;
}

::-webkit-scrollbar { width: 8px; height: 10px;}
::-webkit-scrollbar-track {  background-color: #646464;}
::-webkit-scrollbar-track-piece { background-color: $dark-grey;}
::-webkit-scrollbar-thumb { height: 50px; background-color: #666; border-radius: 3px;}
::-webkit-scrollbar-corner { background-color: #646464;}
::-webkit-resizer { background-color: #666;}

.mainContainer {
  /*background-color: $main-background;*/
}

@keyframes darkComing {
  0%   {height: 0;}
  100% {height: 100%;}
}

.mainContainer .darkContainer {
  position: fixed;
  height: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $dark-blue;
  animation-name: darkComing;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  z-index: -1;
}

h1 {
  font-family: $font-stack;
  font-size: 36px;
  color: $white;
}

h2 {
  font-family: $font-stack;
  font-size: 24px;
  color: $white;
}

h3 {
  font-family: $font-stack;
  font-size: 21px;
  font-weight: 400;
  color: $dark-blue;
}

p {
  font-family: $font-stack;
  font-size: 14px;
  color: $main-background;
}

/*
LINKS
*/

a{
  font-family: $font-stack;
  font-size: 14px;
  color: $tonic-blue;
  opacity:1;
  /*-o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;*/
  cursor: pointer;
}

a:hover {
  opacity:0.8;
}

.btn {
  display: inline-block;
  cursor: pointer;
  line-height: 48px;
  font-size: 14px;
  text-decoration: none;
  height: 48px;
  background-color: $tonic-blue;
  color: $white;
  border: none;
  border-radius: 8px;
  width: auto;
  margin-bottom: 0px;
  padding-top: 0;
  padding-left: 30px;
  padding-right: 30px;
  text-transform: uppercase;
  box-shadow: 0px 5px 9px rgba(36, 117, 130, 0.2);
  /*-o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;*/
}

.btn.icon-only {
  padding-left: 20px;
  padding-right: 20px;
}

.btn svg {
  margin-right: 10px;
}

.btn.icon-only svg {
  margin-right: 0;
}

.btn-submit {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.btn-secondary {
  border:solid 1px $tonic-blue;
  background-color: $white;
  color: $dark-blue
}

.btn:hover {
  opacity:0.8;
  color: $white;
}

.btn-secondary:hover {
  background-color: #f7f7f7;
  color: $dark-blue
}

/*
MESSAGES
*/

.message {
  font-family: $font-stack;
  font-size: 14px;
  color: #fff;
  background-color: $confirm-color;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
}

.error {
  text-align: center;
  color: $error-color;
  font-family: $font-stack;
  font-size: 14px;
  font-weight: 700;
}

.confirm {
  text-align: center;
  color: $confirm-color;
  font-family: $font-stack;
  font-size: 14px;
  font-weight: 700;
}

.message.error {
  color: #fff;
  background-color: $error-color;
}

.message.confirm {
  color: #fff;
  background-color: $confirm-color;
}

.tooltip {
  font-family: $font-stack;
  font-size: 14px;
  line-height: normal;
  color: $dark-grey;
}

/*
CONTAINER
*/

.page-container {
  margin-top: 40px;
  margin-bottom: 100px;
  margin-left: 200px;
  margin-right: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.page-container.add-back {
  background-color: rgba(0,0,0,.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  max-width: initial;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-container.add {
  background-color: $white;
  width: 350px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  /*transition: .4s;*/
  height:60%;
  .container{
      height: calc(100% - 20px);
      overflow-y: auto;
  }
}

.page-container.view-container {
  /*background-color: $white;
  position: fixed;
  right: 20px;
  left: 220px;
  top: 20px;
  bottom: 20px;
  margin: 0;
  border-radius: 8px;
  padding: 20px;
  z-index: 3;
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  transition: .4s;
  overflow-y: auto;*/

  h2{
    svg{
      margin-right: 10px;
    }
  }

}

.page-container .page-container-illu{
  position: fixed;
  bottom: 70px;
  left: 100px;
  right: 100px;
  text-align: center;
  z-index: -1;
}

.page-container .page-container-illu img{
  width:80%;
  opacity:0.5;
}

.page-container .page-container-illu-desk{
  position: fixed;
  right: 100px;
  text-align: center;
  z-index: -1;
  width: 40%;
}

.page-container .page-container-illu-desk img{
  width:90%;
  opacity: 0.5;
}

.page-container .page-container-illu-card{
  position: fixed;
  right: 100px;
  bottom: 100px;
  text-align: center;
  z-index: -1;
  width: 40%;
}

.page-container .page-container-illu-card img{
  width:90%;
  opacity: 0.5;
}

.page-container .page-container-illu-share{
  position: fixed;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: -1;
  width: 70%;
}

.page-container .page-container-illu-share img{
  width:90%;
  opacity: 0.5;
}

.data-container {
  position: absolute;
  left: 120px;
  right: 280px;
  top: 90px;
  bottom: 80px;
  overflow-y: auto;
}

.container {
  padding: 20px;
  margin-top: 20px;
}

.blurred{
  pointer-events: none;
  cursor: not-allowed;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='svgMask'><feGaussianBlur stdDeviation='10' /></filter></svg>#svgMask");
  filter: blur(10px);
  -webkit-filter: blur(10px);
  -moz-filter: blur(10px);
  -o-filter: blur(10px);
  -ms-filter: blur(10px);
}

.container .label-file {
    cursor: pointer;
    color: #00b1ca;
    font-weight: bold;
}
.container .label-file:hover {
    color: #25a5c4;
}

// et on masque le input
.container .input-file {
    display: none;
}

.container-form {
  width: 350px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.page-title {
  display: inline-block;
  font-size: 20px;
  position: relative;
  margin-top: 4px;
  margin-bottom: 0;
  margin-left: 0;
  vertical-align: top;
}

.page-title .title-number{
  font-weight: bold;
}

.limitation-box {
  display: inline-block;
  height: 24px;
  line-height: 24px;
  border-radius: 8px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  margin-top: 6px;
  background: rgba(18,19,22,0.1);
}

.user-thumbnail{
  min-width:26px;
  width: 26px;
  height: 26px;
  display: inline-block;
  line-height: 26px;
  text-align: center;
  margin-right: 10px;
  color: #121316;
  font-weight: bold;
  border-radius: 30px;
  box-shadow: $box-shadow-small;
  background-color: #cccccc;
  font-family: $font-stack;
}

.user-name{
  width: 100px;
  min-width:100px;
  display: inline-block;
}

.user-thumbnail.big{
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
}

.page-back {
  color: $white;
  display: inline-block;
  margin: 0;
  vertical-align: top;
  margin-left: 10px;
  margin-top: 16px;
}

@keyframes titleUnderline {
  0%   {width: 0;}
  100% {width: 50%;}
}

.page-title:after {
  position: absolute;
  content: '';
  height: 3px;
  bottom: 0px;
  top: 32px;
  left: 0;
  right: 0;
  width: 0;
  background: $tonic-blue;
  animation-name: titleUnderline;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.page-title-form,
.page-title-link {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}

.page-subtitle {
  display: block;
  margin-top: 20px;
  margin-left: 10px;
}

.page-title-form {
  margin-bottom: 0;
}

.page-container-header{
  margin-right: -40px;
    float: right;
    margin-top: -40px;
}

/*
categories
*/

.category{
  background-color: $white;
  border: solid 1px rgba(5,199,179,0.15);
  border-radius: 8px;
  margin-top: 5px;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

.category.disabled{
  background-color: $disabled;
}

.category input{
  margin:0;
  margin-right: 10px;
  /*line-height: 44px;*/
}

.category p, .category a{
  display:inline-block;
  margin: 0;
  line-height: 42px;
  color:$dark-blue;
}

.category .category-title {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: calc(100% - 194px);
  vertical-align: top;
}

.category p.right, .category a.right{
  float:right;
  padding-left: 20px;
  padding-right: 20px;
  border-left: solid 1px #D7E6E9;
}

.category a, .category a.right{
  cursor:pointer;
  padding-right: 0;
}

.category a:hover{
  color:$tonic-blue;
}

/*
add btn
*/

.add-btn {
  padding-left: 0;
  padding-right: 0;
  width:48px;
  text-align:center;
  line-height: 60px;
}

.add-btn svg {
  width: 14px !important;
  height: 14px;
  margin-right: 0;
  padding: 5px;
  border-radius: 100%;
}

/*
back btn
*/

.back-btn {
  line-height: 60px;
  display: inline-block;
  margin-right: 20px;

  a{
    font-size: 24px;
    color: $tonic-blue;
    text-decoration: none;
    svg {
      vertical-align: top;
      margin-top: 22px;
      margin-right: 5px;
    }
  }

}
