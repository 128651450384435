
/*RESPONSIVE*/

@keyframes slideInBottomDocumentsResponsive {
  0%   {top: 100%;}
  100% {top: 20px;}
}

@keyframes slideInBottom {
  0%   {bottom: -50px;}
  100% {bottom: 20px;}
}

@media screen and (min-width: 1023px) {
  .navbar-admin{
    margin-top: 14px;
    border-top: solid 1px #000;
    padding-top: 14px;
  }
}

@media screen and (max-width: 1023px) {
  .hideOnTablet {
    display: none
  }
  .page-vizualisation-content-documents {
    left: 0 !important;
    right: 0 !important;
    bottom: 70px !important;
    animation-name: slideInBottomDocumentsResponsive !important;
  }
  .page-vizualisation-modes {
    display: none;
  }
  #display-date{
      margin: 0 0 0 0;
      display:'inline-block'
  }
}

@media screen and (max-width: 790px) {
  .hideOnMobile {
    display: none !important;
  }
  .showOnMobile {
    display: block !important;
  }
  .col-1-4 {
    width:100%;
    margin-right: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .col-1-3 {
    width:100%;
    margin-right: 0;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .col-2-3 {
    width:100%;
    margin-left: 0;
  }
  .col.half {
    width:100% !important;
    margin-left: 0;
    padding: 0;
  }
  .container {
    margin-left: 0 !important;
  }
  .btn.headerBtn {
    svg{
      margin-right: 0;
    }
    span{
      display:none
    }
  }
  .line .lineContent {
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    width: calc(100% - 54px);
    display: inline-block;
  }
  .line p.line-name {
    width: calc(100% - 86px);
  }

  .container-add {
    margin-left: 30px !important;
    width: calc(100% - 60px) !important;
  }
  .tabs{
    text-align: center;
    margin-left: 0
  }
  .tab{
    padding-left: 10px !important;
    padding-right: 10px !important
  }
  .page-vizualisation-documents-detail h1{
    font-size: 24px
  }

  .page-container{
    margin-left: 0 !important;
    margin-right: 0 !important;
    &.view-container{
      left:20px !important;
      bottom:100px !important;
    }
  }
  .sidebar{
      right: 0;
      height: 70px;
      left: 0;
      bottom: 0;
      top: auto;
      width: auto;
      border-top: solid 2px $tonic-blue;
      border-right: none;

    .navbar{
      margin-top: 0;
      display:flex;
      .navbar-brand{
        margin-left: 5px;
        img{
          height: 40px;
          margin-top: 15px;
          margin-left: 15px;
          width: 40px;
        }
      }
      .navbar-center{
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 150px);
        height: 70px;
        margin-left: 24px;
        margin-top: 0;
        li{
          display:flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto;
          line-height: initial;
          padding: 0;
          margin:0;
          border-right:none;
          a{
            font-size: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            svg{
              font-size: 18px;
              margin-bottom: 5px;
            }
          }
        }
        li.active{
            border: none;
          background:transparent;
          box-shadow: none;
        }
      }
    }

  }
}
