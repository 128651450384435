@keyframes headerOpen {
  0%   {top: -70px;}
  100% {top: 0;}
}

@keyframes navbarLineOut {
  0%   {width: 50%;}
  100% {width: 0;}
}

.sidebar {
  width: 190px;
  top: 0px;
  left: 0px;
  bottom: 0px;
  position: fixed;
  display: block;
  z-index: 2;
  background-color: $dark-grey;
  border-radius: 0 0 0 0;
  /*border-right: solid 2px $tonic-blue;
  box-shadow: $box-shadow;*/
}

.sidebar.dark {
  background-color: $dark-blue;
  /*box-shadow: 0 0 5px 5px $dark-blue;*/
}

.sidebar.open {
  animation-name: headerOpen;
  animation-duration: .5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

.sidebar h3 {
  display: inline-block;
  font-size: 30px;
  margin-top: 24px;
}

.sidebar.dark h3 {
  color: $white;
}

.sidebar .navbar {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-top: 40px;
  text-align: center;
}

.sidebar .navbar-brand {
  display: inline-block;
  vertical-align: top;
  color: $dark-blue;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
}

.sidebar .navbar-brand:hover {
  color:$tonic-blue;
}

.sidebar .navbar-brand img {
  display: inline-block;
  vertical-align: top;
  margin-top: 0;
  height: 50px;
  margin-right: 10px;
}

.sidebar .navbar-brand span {
  height: 50px;
  line-height: 50px;
  color: $tonic-blue;
  font-size: 18px;
  font-weight: bold;
  display: block;
  vertical-align: top;
  text-transform: uppercase;
}

.sidebar .navbar .navbar-center {
  list-style: none;
  padding-left: 0;
  display: block;
  margin: 0;
  margin-top: 60px;
  text-align: left;
}

.sidebar .navbar li {
  display: block;
  margin-left: 0;
  margin-right: 0;
}

.sidebar .navbar li svg {
  width: 40px;
  text-align: center;
}

.sidebar .navbar .navbar-profile {
  list-style: none;
  padding-left: 0;
  display: block;
  margin: 0;
  width: 190px;
  text-align: center;
  position: absolute;
  bottom: 30px;
  svg{
    margin-right: 0;
  }
}

.sidebar .navbar li a {
  color: $white;
  font-size: 15px;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.sidebar .navbar li a:hover {
  color: $tonic-blue;
}

.sidebar .navbar li.active,
.sidebar .navbar li.active:hover {
  /*border-bottom: solid 3px $tonic-blue;*/
}

.sidebar .navbar li.active a {
  color: $tonic-blue;
}

.sidebar .sidebar.dark .navbar li.active a {
  color: $white;
}

.sidebar .navbar .navbar-center li {
  display: block;
  margin-left: 0;
  margin-right: 0;
  height: 40px;
  line-height: 40px;
  padding-left: 15px;
  width: calc(100% - 20px);
  border-right: solid 5px #121316;
}

.sidebar .navbar .navbar-center li.active {
  border-right: solid 5px $tonic-blue;
  background: #121316;
  box-shadow: inset 20px 20px 60px #0f1013, inset -20px -20px 60px #151619;
}
