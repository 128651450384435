.customLabel {
    position: absolute;
    right: 5%;
    top: 0;
    margin-top: 5px;
    line-height: 35px;
    text-align: right;
    -webkit-transform: translateX(70px);
    -moz-transform: translateX(70px);
    -o-transform: translateX(70px);
    -ms-transform: translateX(70px);
    transform: translateX(70px);
    /*-webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;*/
    -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    opacity: 0;
}

.row {
  position:relative;
}

.row:hover .customLabel {
  -ms-filter: "progid: DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -o-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

label {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  color: #999999;
  margin-top: 10px;
}

input[type="password"],
input[type="text"],
input[type="email"],
input[type="number"],
input[type="date"] {
  height: 35px;
  border: solid 1px $tonic-blue;
  border-radius: 8px;
  width: calc(100% - 32px);
  padding: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
  font-size: 14px;
  background-color: #F9F9F9;
}

input[type="text"].search {
  background-color: #FAFAFA;
  width: 85%;
  margin-top: 0;
}

.document{
    input[type="date"] {
        height: 30px;
          border: none;
          border-radius: 0;
          width: calc(100% - 8px);
          padding: 0 !important;
          margin-bottom: 15px;
          margin-top: 5px;
          font-size: 14px;
          background-color: #999;
          text-align: center;
          border: none;
          position: relative;
    }
    input[type="date"]::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
    }
}

textarea {
  border: 1px solid $tonic-blue;
  border-radius: 8px;
  width: calc(100% - 30px) !important;
  padding: 15px;
  margin-bottom: 15px;
  margin-top: 5px;
  font-size: 12px;
  display: block;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.1);
  font-family: 'Open Sans', sans-serif;
}

select {
  display: block;
  width: 100%;
  text-align: center;
  height: 116px;
}

.Select {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 5px;
}

.Select{
  text-align: left !important;
}

.Select input{
  margin:0;
  height: auto;
  line-height: 18px;
}

.Select .css-yk16xz-control, .Select .css-1pahdxg-control {
  border: 1px solid $tonic-blue;
  border-radius: 8px;
  box-shadow: 0 3px 8px 0 rgba(0,0,0,.1);
  //background: transparent;
}

.Select--multi .Select-item {
  border: 1px solid #ccc;
  color: $tonic-blue;
  background-color: #efefef;
}

.Select--multi .Select-item .Select-item-icon {
  border-right: 1px solid #ccc;
  color: #121316;
}

.Select--multi .Select-item .Select-item-icon:hover {
  background-color: #ccc;
}

textarea {
  width: 100%;
  height: 100px;
}
