.lines {
    border-radius: 8px;
    background: $dark-background;
    box-shadow: $box-shadow;
}

.line {
  height: 50px;
  display: inline-block;
  width: 100%;
  line-height: 50px;

  cursor: pointer;
  border-radius: 8px;
  background: $dark-background;
  box-shadow: $box-shadow;
  margin-top: 10px;

  &.project{
      box-shadow:none;
      background:transparent;
      border-radius: 0px;
      height: 34px;
      line-height: 34px;
      margin-top: 0;
      border-bottom: solid 1px $dark-grey;
      vertical-align: top;
  }
  &.project:last-child{
      border-bottom: none;
  }

  &.user{
      box-shadow:none;
      background:transparent;
      border-radius: 0px;
      height: 34px;
      line-height: 34px;
      margin-top: 0;
      border-bottom: solid 1px $dark-grey;
      vertical-align: top;
  }
  &.user:last-child{
      border-bottom: none;
  }
}

.line.disabled{
  background: $disabled;
}

.line.dark {
  background: #071144;
  border: none;
  box-shadow: none;
}

.line a {
  text-decoration: none;
}

.line:hover {
  background: $dark-grey;
}

.line.dark:hover {
  background-color: #091350;
}

.line p {
  display: inline-block;
  margin: 0;
}

.line p.line-name {
  overflow: hidden;
  white-space: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: calc(100% - 300px);
}

.line .price-lines {
    height: 10px;
    line-height: 0;
    vertical-align: top;
    display: block;
    .price-line {
        background-color:#c92b2b;
        display: inline-block;
        height: 10px;
        &.price-line-paid{
            background-color:#80c92b
        }
        &.price-line-invoice{
            background-color:#FF8F4C
        }
    }
}

.line.dark p {
  color:#fff;
}

.line svg {
  margin-left: 20px;
  margin-right: 10px;
  color:#fff;
}

.line.dark svg {
  color:#fff;
}

.tools {
  display: inline-block;
  float: right;
}

.tools a {
  font-size: 16px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 8px;
  display: inline-block;
  background-color: #ffffff;
  text-align: center;
  margin-right: 10px;
  svg{
      margin:0;
      color:#fa5c4f;
  }
}

.line.selected {
  font-weight: bold
}

.list-item{
  color: #151845;
  display: block;
  border-bottom: solid 1px #dedfec;
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  position: relative;
}

.list-item:hover{
  background-color: #dedfec;
}

.list-item.selected:after {
  position: absolute;
  content: "";
  height: 100%;
  bottom: 0;
  top: 0;
  left: -20px;
  right: 0;
  width: 8px;
  border-radius: 0 4px 4px 0;
  background: #00e3cc;
}

.list-item a {
  text-decoration: none;
}

.list-item p {
  display: inline-block;
  margin: 0;
}

.list-item.selected p {
  font-weight: bold
}

.list-item svg {
  margin-left: 11px;
  margin-right: 10px;
  color:#0A1760;
}

.list-item .tools {
  display: inline-block;
  float: right;
  margin-right: 10px;
}

.list-item .tools a {
  font-size: 16px;
}
