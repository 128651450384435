.col-container {
  display:block;
  width:100%;
}

.col {
  display:inline-block;
  padding-left: 39px;
  padding-right: 39px;
  vertical-align: top;
}

.col:first-child {
  padding-left: 0;
  padding-right: 39px;
  border-left: none;
}

.col:last-child {
  padding-left: 39px;
  padding-right: 0;
}

.col.half {
  width: calc(50% - 42px);
}

/*
COLONNES
*/

.col-1-4{
  display: inline-block;
  vertical-align: top;
  width:calc(25% - 60px);
}

.col-1-3{
  display: inline-block;
  vertical-align: top;
  width:calc(33.33% - 40px);
  /*margin-right: 10px;*/
}

.col-2-3{
  display: inline-block;
  vertical-align: top;
  width:calc(66.66% - 40px);
  /*margin-left: 10px;*/
}
