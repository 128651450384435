.holiday{
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    text-decoration: underline;
    height: 24px;
    line-height: 24px;
}
.holiday.active{
    background-color: #fa5c4f;
    color:$white;
    border-radius: 8px;
}

.holidays {
  display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  overflow-x: auto;

  .holiday {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 5px;

    p {
      width: 110px;
      margin: 0px;
    }

    .day {
      width: 30px;
      text-align: center;
      border-radius: 8px;
      box-shadow: $box-shadow-small;
      background-color: $dark-grey;
      border: dashed 1px #fa5c4f;
      height: 24px;
      line-height: 24px;
      margin-right: 5px;
    }

    .day.select {
      background-color: #fa5c4f;
      border:solid 1px #fa5c4f;
      color:#fff;
      width:50px;
    }

    .day.homeoffice {
        width:50px;
    }

    .day.publicHoliday {
        color: #707070;
        box-shadow:none;
        background-color: $dark-grey;
        border:solid 1px $dark-grey;
        cursor: not-allowed;
    }

    .day.weekend {
        background-color: $dark-grey;
        border:solid 1px #707070;
        color:#707070;
        cursor: not-allowed;
    }

    .day.busy {
        color: #707070;
        background-color: $dark-grey;
        border:solid 1px $dark-grey;
        cursor: not-allowed;
    }

    .day.half{
        height: 14px;
        line-height: 14px;
    }

    .day.open {
      width: 250px;
      padding-left: 10px;
      padding-right: 10px;
      background: #e0c7c5;
      background-color: #e0c7c5;
      height: 24px;
      line-height: 24px;
      a {
          display: inline-block;
          padding-left: 5px;
          padding-right: 5px;
          text-decoration: underline;
      }
      a.active{
          background-color: #fa5c4f;
          color:$white;
          border-radius: 8px;
      }
    }

  }

}
