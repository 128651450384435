.tiles {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(200px,1fr));
  grid-auto-rows: 0;
}

.tile {
  height: 50px;
  display: inline-block;
  line-height: 50px;

  cursor: pointer;
  border-radius: 8px;
  background: #f4f5f7;
  box-shadow: $box-shadow;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 20px;
  padding-right: 20px;
  /*-o-transition: 0.4s;
  -ms-transition: 0.4s;
  -moz-transition: 0.4s;
  -webkit-transition: 0.4s;
  transition: 0.4s;*/
}

.tile.homeTile {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  line-height: 50px;
  text-align: center;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width:20px;
  }
}

.tile.homeTile:hover {
  box-shadow: none;
}

.checkout{
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: rgba(36, 117, 130, 0.2) 0px 5px 9px;
  width: 60%;
  margin-left: 20%;
}

.tileAccount.active {
  border: solid 1px $tonic-blue;
  cursor:not-allowed;
}

.tile .tile-wrapper, .tileAccount .tile-wrapper {
  overflow: hidden;
  position: relative;
}

.tile .tile-thumbnail, .tileAccount .tile-thumbnail {
  background-color: $dark-blue;
  min-height: 60px;
  border-radius: 8px 8px 0px 0px;
}

.tile .tile-thumbnail img {
  border-radius: 8px 8px 0px 0px;
  display: block;
}

.tile:hover, .tileAccount:hover {
  background-color: #efefef;
}

.tile .tile-content, .tileAccount .tile-content {
  padding: 10px;
  border-radius: 0px 0px 8px 8px;
}

.tile p, .tileAccount p {
  display: inline-block;
  margin: 0;
  color: $dark-blue;
}

.tile svg, .tileAccount svg {
  color:$dark-blue;
}

.tile .tools {
  margin-top: -25px;
  position: absolute;
  right: 10px;
}

.tile .tools a {
  font-size: 14px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  background-color: $main-background;
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  border-radius: 16px;
  text-align: center;
  margin-left: 10px;
}

.tile .infos {
  position: absolute;
  top:10px;
  left: 0;
}

.tile .infos .info {
  font-size: 14px;
  width: 32px;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  background-color: rgba(255,255,255,.7);
  box-shadow: 0 5px 9px rgba(36,117,130,.2);
  border-radius: 16px;
  text-align: center;
  margin-left: 10px;
}
