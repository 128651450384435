.page-login{
  position:absolute;
  display:flex;
  flex-direction: row;
  top:0;
  bottom:0;
  left:0;
  right:0;
  justify-content: center;
  background-color: $main-background;

  @keyframes imgLogin {
    0%   {margin-bottom: -80px;}
    100% {margin-bottom: 0;}
  }

  @keyframes imgLoginOpen {
    0%   {opacity: 1;}
    100% {opacity: 0;}
  }

  @keyframes formLogin {
    0%   {margin-top: -100px; opacity:0}
    100% {margin-top: 0; opacity:1}
  }

  @keyframes formLoginOpen {
    0%   {opacity: 1;}
    100% {opacity: 0;}
  }

  @keyframes opacityIn {
    0%   {opacity: 0;}
    100% {opacity: 1;}
  }

  label{
    color:$tonic-blue;
    display:none;
  }

  .header-login{
    position:absolute;
    display: flex;
    align-items:center;
    justify-content:flex-end;
    flex-direction: column;
    top: 0;
    bottom: 50%;
    left: 0;
    right: 0;
    text-align: center;
  }

  .form-login h1 {
    font-size: 20px;
    position: relative;
    color:$tonic-blue;
    opacity:0;
    animation-name: opacityIn;
    animation-delay: 1s;
    animation-duration: 1s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .left-login, .right-login{
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
  }

  .right-login{
    background-color: #fff;
  }

  .left-login .illustration{
    width:80%;
  }

  .left-login.open .illustration{
    width:80%;
  }

  .logo{
    margin-bottom: -80px;
    width:160px;
    animation-name: imgLogin;
    animation-delay: .9s;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .right-login.open .logo{
    margin-bottom: 30px;
    animation-name: imgLoginOpen;
    animation-delay: 0s;
    animation-duration: .3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .form-login{
    margin-top: -100px;
    width: 300px;
    opacity:0;
    text-align: center;
    animation-name: formLogin;
    animation-delay: .9s;
    animation-duration: .5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

  .form-login.open{
    margin-top: 30px;
    animation-name: formLoginOpen;
    animation-delay: 0s;
    animation-duration: .3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }

}
