.modal {
    display: none;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: rgb(36,41,51);
    background-color: rgba(36,41,51,0.8);

    &-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &-content {
      box-sizing: border-box;
      background-color: #ffffff;
      padding: 20px;
      border: 1px solid #888;
      min-width: 450px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 2px 10px 0 rgba(0,0,0,.1);
      border-radius: 4px;

      .modal-title {
        color: #BB9C37;
        font-weight: normal;
      }

      .close {
        float: right;
        /*transition: color 0.2s ease-in-out;*/

        &:hover {
          color: 'red';
          cursor: pointer;
        }
      }

      .button-row {
        max-width: 100%;
        margin-top: 20px;
      }
    }

    &.is-open {
      display: block;
    }
}
