.documents {
  display:flex;
  flex-direction: row;
  flex-wrap: wrap;

  .document {
    width: 105px;
    height: 149px;
    margin-right: 10px;
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-radius: 3px;
    box-shadow: $box-shadow-small;
    background-color: $dark-grey;
    text-decoration: none;

    svg {
      font-size: 36px;
    }
  }

}
